import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import Img from "@/components/Img";
import { useState, useEffect } from "react";
import { Popover, Typography } from "antd";
import "./style.less";
import Speed from "@/static/images/speed.png";
interface NodeMapProps {
  data: any; //数据
  tab: string; //当前展示tab
  webData?: any; //平台数据
  source?: string; //使用页面
  jump?: boolean; //滚动触发动画
}
const NodeMap = (props: NodeMapProps) => {
  const [nodeValue, setNodeValue] = useState("");
  const [randomArr, setRandomArr] = useState<Array<string>>([]);
  const [nodeData, setNodeData] = useState<Array<any>>([]);
  const router = useRouter();
  const locale = router.locale || "en-US";
  const { data, tab, source, jump = false } = props;
  const intl = useIntl();
  let timer: any;
  let nodeTimer: any;
  useEffect(() => {
    if (document.body.clientWidth <= 768 || (!jump && source === "home")) {
      setNodeData([]);
      clearInterval(timer);
      return;
    }
    // 随机取1-3个数
    setNodeData([]);
    const arr = data.filter((v: any) => v.tab?.includes(tab));
    timer = setInterval(() => {
      const arr1: Array<string> = [];
      if (tab === "LightNode") {
        for (let i = 0; i < 4; i++) {
          const random: string = randomA(arr1, arr.length);
          arr1.push(random);
        }
      } else {
        const math: number = Math.floor(Math.random() * 3 + 1);
        for (let i = 0; i < math; i++) {
          const random: string = randomA(arr1, arr.length);
          arr1.push(random);
        }
      }
      setRandomArr(arr1);
    }, 2200);
    nodeTimer = setInterval(() => {
      setNodeData((n) => {
        const _len = n.length;
        if (_len < arr.length) {
          return [...n, arr[_len]];
        } else {
          clearInterval(nodeTimer);
          return n;
        }
      });
    }, 100);
    return () => {
      clearInterval(timer);
      clearInterval(nodeTimer);
    };
  }, [tab, jump]);
  const goToSpeed = (v: string | undefined) => {
    if (v) {
      window.open(`/${locale}/speed/${v}`);
    }
  };
  const randomA = (arr: Array<string>, length: number): string => {
    const random = String(Math.floor(Math.random() * length + 1));
    if (!length) return random;
    if (arr.includes(random)) {
      return randomA(arr, length);
    } else {
      return random;
    }
  };

  return (
    <>
      <div
        className={tab === "VU&DO" ? "node-map node-map-other" : "node-map"}
        id="nodeMap"
      >
        <div className="u-map-list" id="u-maplist">
          <dl>
            {data?.map((v: any, index: number) => {
              return (
                <dd
                  data-value={v.value}
                  key={index}
                  onMouseOver={() => {
                    setNodeValue(v.value);
                  }}
                  onClick={() => {
                    goToSpeed(v.regionCode);
                  }}
                  className={
                    v.tab?.includes(tab)
                      ? `${nodeValue === v.value ? "active" : ""}`
                      : "none"
                  }
                  onMouseLeave={() => {
                    setNodeValue("");
                  }}
                  id={
                    index === data.length - 1
                      ? "node-bottom"
                      : index === 0
                      ? "node-top"
                      : ""
                  }
                >
                  {v.countryImgCode ? (
                    <span className={`fi fi-${v.countryImgCode} flag`}></span>
                  ) : (
                    <Img src={v.countryImg} alt={v.country?.[locale]} />
                  )}
                  <span className="locale-name">
                    <Typography.Text ellipsis={true}>
                      {v?.city?.[locale]}
                    </Typography.Text>
                  </span>
                  {v.regionCode ? (
                    <Img
                      className="mapspeed"
                      onClick={() => {
                        goToSpeed(v.regionCode);
                      }}
                      src={Speed}
                      alt={intl.formatMessage(
                        {
                          id: "components.NodeMap.speedTest",
                        },
                        { platformName: global.__webInfo?.platformName }
                      )}
                    />
                  ) : null}
                </dd>
              );
            })}
          </dl>
        </div>
        <div className="u-map-box">
          {source === "home" ? (
            <div className="u-title">
              <b>{global.__webInfo?.platformName}</b>{" "}
              {intl.formatMessage({ id: `pages.home.NodeMap` })}
            </div>
          ) : null}
          <div className="u-map">
            {nodeData?.map((v: any, index: number) => {
              return (
                <div
                  key={index}
                  data-value={v.value}
                  style={{
                    top: ((v.position.top / 510) * 100).toFixed(2) + "%",
                    left: ((v.position.left / 1050) * 100).toFixed(2) + "%",
                  }}
                  onMouseOver={() => {
                    setNodeValue(v.value);
                  }}
                  onMouseLeave={() => {
                    setNodeValue("");
                  }}
                  className={`animated shakeX ${
                    nodeValue === v.value ? "active" : ""
                  }`}
                >
                  <span></span>
                  <Popover
                    visible={nodeValue === v.value}
                    overlayClassName={
                      v.regionCode && tab === "LightNode"
                        ? "node-map-popover"
                        : "node-map-popover noContent"
                    }
                    content={
                      v.regionCode && tab === "LightNode" ? (
                        <div
                          onClick={() => {
                            goToSpeed(v.regionCode);
                          }}
                          title={v?.city?.[locale] + " IP test"}
                        >
                          {intl.formatMessage({
                            id: "pages.southeastaisa.node.btn",
                          })}
                        </div>
                      ) : null
                    }
                    title={
                      <label
                        title={
                          v?.city?.[locale] + "," + v.country[locale] + " VPS"
                        }
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            const _goLocale =
                              locale === "zh-CN" || locale === "zh-HK"
                                ? "zh/"
                                : "";
                            const html =
                              "https://go.lightnode.com/" +
                              _goLocale +
                              (v?.url || "");
                            window.open(html);
                          }}
                        >
                          {v.countryImgCode ? (
                            <span
                              className={`fi fi-${v.countryImgCode} flag`}
                            ></span>
                          ) : (
                            <Img src={v.countryImg} alt={v.country?.[locale]} />
                          )}
                          {v?.city?.[locale]}
                        </div>
                      </label>
                    }
                    trigger="hover"
                  >
                    <div
                      className={
                        randomArr.some((m: any) => m === v.value) && !nodeValue
                          ? "purple1 purple"
                          : "purple"
                      }
                      style={{
                        animationDelay: `${
                          randomArr.indexOf(v.value) > 0 &&
                          randomArr.indexOf(v.value) < 3
                            ? randomArr.indexOf(v.value) * 500
                            : 0
                        }ms`,
                      }}
                    ></div>
                  </Popover>
                </div>
              );
            })}
          </div>
          <div className="count animated bounceIn">{nodeData.length}</div>
        </div>
      </div>
    </>
  );
};
export default NodeMap;
